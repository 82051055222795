<template>
  <div class="col-md-8 col-lg-9 mx-auto pt-5">
    <div class="row p-0 mx-auto mb-2 g-0" id="course-page-header">
      <div class="col text-start">
        <h1 class="card-title mb-4" v-if="editing">Edit Class</h1>
        <h1 class="card-title mb-4" v-else>Create Class</h1>
      </div>
      <div class="col text-end">
        <button type="button" class="btn background-dark text-white
        rounded-pill shadow ms-2" @click="setActive(0)"
        :class="{'tab-active': activeTab===0}">
          Course Details
        </button>
        <button type="button" class="btn background-dark text-white
        rounded-pill shadow ms-2" @click="setActive(1)"
        :class="{'tab-active': activeTab===1}" :disabled="!courseId">
          Members
        </button>
        <button type="button" class="btn background-dark text-white
        rounded-pill shadow ms-2" @click="setActive(2)"
        :class="{'tab-active': activeTab===2}" :disabled="!courseId">
          Topics
        </button>
        <!-- <label>
          <input type="radio" id="details" :value="0" class="tab-radio"
            v-model="activeTab">
          <div type="button" class="btn background-dark text-white
          rounded-pill shadow ms-2">
            Course Details
          </div>
        </label>
        <label>
          <input type="radio" id="topics" :value="1" class="tab-radio"
            v-model="activeTab" :disabled="!courseId">
          <div type="button" class="btn background-dark text-white
          rounded-pill shadow ms-2" :class="{'btn-disabled': !courseId}">
            Members
          </div>
        </label>
        <label>
          <input type="radio" id="members" :value="2" class="tab-radio"
            v-model="activeTab" :disabled="!courseId">
          <div type="button" class="btn background-dark text-white
          rounded-pill shadow ms-2" :class="{'btn-disabled': !courseId}">
            Topics
          </div>
        </label> -->
        <!-- <label>
          <input type="radio" id="structure" :value="3" class="tab-radio"
            v-model="activeTab" :disabled="!courseId">
          <div type="button" class="btn background-dark text-white
          rounded-pill shadow ms-2" :class="{'btn-disabled': !courseId}">
            Course Structure
          </div>
        </label> -->
      </div>
    </div>
    <CourseDetails v-if="activeTab===0" :editing="editing" :course-id="courseId"
    ref="tab0">
    </CourseDetails>
    <CourseMembers v-else-if="activeTab===1" :editing="editing" :course-id="courseId"
    ref="tab1">
    </CourseMembers>
    <CourseTopics v-else-if="activeTab===2" :editing="editing" :course-id="courseId"
    ref="tab2">
    </CourseTopics>
    <div v-else> {{activeTab}} </div>
    <!-- <div class="mt-5 text-end">
      <div v-if="editing">
        <button type="button" class="float-end btn btn-primary"
        @click="submitNewClass">Save changes</button>
        <button type="button" class="float-end btn btn-secondary me-2"
        @click="discardChanges">Cancel</button>
      </div>
      <div v-else>
        <button type="button" class="btn btn-primary ms-2"
        @click="submitNewClass" v-show="activeTab==3">Create class</button>
        <button type="button" class="btn btn-primary ms-2"
        @click="activeTab-=1" v-show="activeTab!=0">Back</button>
        <button type="button" class="btn btn-primary ms-2"
        @click="activeTab+=1" v-show="activeTab!=3">Next</button>
        <button type="button" class="btn btn-secondary ms-2"
        @click="cancelNewClass">Cancel</button>
      </div>
    </div> -->
  </div>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';
import CourseDetails from '../components/CourseDetails.vue';
import CourseMembers from '../components/CourseMembers.vue';
import CourseTopics from '../components/CourseTopics.vue';

export default {
  name: 'CreateClass',
  components: {
    CourseDetails,
    CourseMembers,
    CourseTopics,
  },
  computed: {
    loadScreen() {
      return this.$store.state.loadScreen;
    },
    courseId() {
      if ('cId' in this.$route.query) {
        return this.$route.query.cId;
      }
      return null;
    },
  },
  data() {
    return {
      activeTab: 0,
      editing: false,
    };
  },
  methods: {
    cancelNewClass() {
      this.$router.replace({ path: '/courses/' });
    },
    nextTab() {
      this.activeTab += 1;
    },
    prevTab() {
      this.activeTab -= 1;
    },
    setCourse(courseId) {
      this.courseId = courseId;
    },
    changesMade() {
      return this.$refs[`tab${this.activeTab}`].changesMade === true;
    },
    setActive(tab) {
      console.log(this.changesMade());
      if (this.changesMade() && tab !== this.activeTab) {
        Swal.fire({
          title: 'Are you sure you want to leave the page, you have unsaved changes?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
        }).then((result) => {
          if (result.isConfirmed) {
            this.activeTab = tab;
          }
        });
      } else if (tab !== this.activeTab) {
        this.activeTab = tab;
      }
    },
    submitNewClass() {
      if (this.newClass.title === '') {
        Swal.fire({
          title: 'Please give your course a title',
          icon: 'error',
        });
        return;
      }
      if (this.classIDs.students.length === 0) {
        Swal.fire({
          title: 'Cannot create course with no students',
          text: 'Please add at least one student',
          icon: 'error',
        });
        return;
      }
      const classData = {
        title: this.newClass.title,
        instructor: this.newClass.instructor,
        staff: this.classIDs.staff,
        students: this.classIDs.students,
        role: this.$store.state.userData.roleId,
      };
      if (this.editing) {
        axios.post(`/api/course/${this.$route.params.cid}/edit`, classData)
          .then(() => {
            this.$router.replace({ path: `/courses/${this.$route.params.cid}/info` });
          });
      } else {
        axios.post('/api/course/create', classData)
          .then(() => {
            this.$router.replace({ path: '/courses/' });
          });
      }
    },
  },
};
</script>

<style scoped>
  .tab-active {
    background-color: var(--accent-1) !important;
    color: var(--dark-color) !important;
  }
  .course-create-content {
    min-height: 20rem;
  }
</style>
