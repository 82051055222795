<template>
  <div v-if="!loadScreen">
    <div class="card left-accent-card course-create-content" >
      <div class="card-body">
        <div class="row mb-5">
          <div class="col-md-10 col-12">
            <input class="form-control" type="text" v-model="topicName"
            @keyup.enter="addTopic">
          </div>
          <div class="col-2 ps-0 text-end">
            <button class="icon-btn btn-line-dark me-2" @click="addTopic">
              <i class="material-icons align-middle">check</i>
            </button>
            <button class="btn-line-danger icon-btn">
              <i class="material-icons align-middle">close</i>
            </button>
          </div>
        </div>
        <div v-for="topic, ind in topics" :key="ind">
          <div class="topic-card row mx-0">
            <div class="col-md-1 col-2">
              <i class="material-icons align-middle">topic</i>
            </div>
            <div class="col-md-10 col-12">
              <p class="h5"> {{topic.name}} </p>
            </div>
            <div class="col-1 text-end">
              <i class="material-icons align-middle text-danger pointer"
              @click="removeTopic(topic, ind)">delete</i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-5 text-end">
      <div>
        <button type="button" class="float-end btn btn-secondary me-2"
        @click="discardChanges">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
// import Swal from 'sweetalert2';;

export default {
  name: 'CourseTopics',
  props: ['editing', 'courseId'],
  components: {
  },
  computed: {
    loadScreen() {
      return this.$store.state.loadScreen;
    },
    userData() {
      return this.$store.state.userData;
    },
  },
  data() {
    return {
      topics: [],
      topicName: null,
    };
  },
  methods: {
    getTopics() {
      this.$store.commit('setLoadScreen', { status: true });
      axios.get('/api/course/getTopics', {
        params: {
          cId: this.courseId,
          role: this.userData.roleId,
        },
      })
        .then((response) => {
          this.topics = response.data.topics;
          this.$store.commit('setLoadScreen', { status: false });
        })
        .catch((err) => {
          this.$store.commit('setLoadScreen', { status: false });
          console.log(err);
        });
    },
    addTopic() {
      this.$store.commit('setLoadScreen', { status: true });
      axios.post('/api/course/addTopic', {
        topic: this.topicName,
        courseId: this.courseId,
        role: this.userData.roleId,
      })
        .then((response) => {
          this.topics.push({ id: response.data.topicId, name: this.topicName });
          this.topicName = null;
          this.$store.commit('setLoadScreen', { status: false });
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit('setLoadScreen', { status: false });
        });
    },
    removeTopic(topic, ind) {
      this.$store.commit('setLoadScreen', { status: true });
      axios.post('/api/course/deleteTopic', {
        topic: topic.id,
        role: this.userData.roleId,
      })
        .then(() => {
          this.topics.splice(ind, 1);
          this.$store.commit('setLoadScreen', { status: false });
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit('setLoadScreen', { status: false });
        });
    },
    discardChanges() {
      this.$router.replace({ path: `/courses/${this.courseId}/info` });
    },
    // updateTopics() {
    //   this.$store.commit('setLoadScreen', { status: true });
    //   axios.post('/api/course/updateTopics', topicData)
    //   .then(() => {
    //     this.$store.commit('setLoadScreen', { status: false });
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     this.$store.commit('setLoadScreen', { status: false });
    //   });
    // },
  },
  mounted() {
    this.getTopics();
  },
};
</script>

<style scoped>
  .topic-card {
    /*position: relative;*/
    /*display: flex;*/
    /*flex-direction: column;*/
    width: 100%;
    word-wrap: break-word;
    background-color: var(--accent-1-tint-1);
    background-clip: border-box;
    padding:  0.5rem;
    margin: 0.5rem 0;
    border-radius: 0 1rem 1rem 0;
    /*border: 1px solid rgba(0, 0, 0, 0.125);*/
  }
  .standard-input {
    outline: none;
    border: none;
    background-color: transparent;
    width: 100%;
  }
</style>
