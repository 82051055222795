<template>
  <div v-if="!loadScreen">
    <div class="card left-accent-card course-create-content" >
      <div class="card-body">
        <form @submit.prevent class="container">
          <div class="row mt-5">
            <div class="col-3">Students</div>
            <div class="col-9">
              <div class="form-row mb-4">
                <div class="input-group row">
                  <input type="text" class="form-control mt-2 student-query-field"
                  placeholder="Search for students..." v-model="studentQuery"
                  @keyup="searchStudents"
                  :class="{ 'query-results' : studentResults.length > 0 }">
                  <div class="position-relative p-0">
                    <div class="autocomplete-results position-absolute"
                    v-if="studentQuery !== '' && studentResults.length > 0">
                      <ul class="autocomplete-item" v-for="(student, idx) in studentResults"
                      :key="idx" :class="{ focus : idx === focusItem }" @click="addStudent(idx)">
                        {{ student.name }}<br>
                        {{ student.username }}<br>
                        {{ student.email}}
                      </ul>
                    </div>
                    <div class="autocomplete-results position-absolute"
                    v-else-if="studentQuery !== '' && studentResults.length === 0">
                      <ul class="autocomplete-item" :class="{ focus : 0 === focusItem }"
                      @click="inviteStudent">
                        <em>Student not found<br>
                        Enter email address in full to invite to join your course</em>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row justify-content-end">
                <button type="button" class="btn btn-outline-danger btn-sm"
                @click="deleteAllStudents"  :disabled="classIDs.students.length == 0">
                  Clear all
                </button>
              </div>
              <table id="studentTable" class="display table table-striped">
              </table>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="mt-5 text-end">
      <div>
        <button type="button" class="float-end btn btn-primary"
        @click="submitNewRoster" :disabled="!changesMade">
          Save changes
        </button>
        <button type="button" class="float-end btn btn-secondary me-2"
        @click="discardChanges">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import Swal from 'sweetalert2';

let table = null;

export default {
  name: 'CourseMembers',
  props: ['editing', 'courseId'],
  computed: {
    loadScreen() {
      return this.$store.state.loadScreen;
    },
    userData() {
      return this.$store.state.userData;
    },
    studentResults() {
      const tempList = [];
      this.studentQueryResults.forEach((student) => {
        const exists = this.classIDs.students.some((e) => (e.userID === student.userID));
        if (!exists) {
          tempList.push(student);
        }
      });
      return tempList;
    },
    changesMade() {
      return this.oldStudentList !== JSON.stringify(this.classIDs.students);
    },
  },
  data() {
    return {
      oldStudentList: null,
      newClass: {
        title: '',
        instructor: '',
        staff: [],
      },
      classIDs: {
        staff: [],
        students: [],
      },
      addStudentDetails: {
        name: '',
        username: '',
        email: '',
        userID: null,
        fieldID: 'addStudent',
        error: 0,
      },
      addingStudentDetails: false,
      studentQuery: '',
      studentQueryResults: [],
      focusItem: null,
    };
  },
  methods: {
    getMembers() {
      this.$store.commit('setLoadScreen', { status: true });
      axios.get('/api/course/getMembers', {
        params: {
          cId: this.courseId,
          // cId: this.$route.query.cId,
          role: this.userData.roleId,
        },
      })
        .then((response) => {
          this.classIDs.students = response.data.studentList;
          this.oldStudentList = JSON.stringify(this.classIDs.students);
          this.$store.commit('setLoadScreen', { status: false });
          setTimeout(() => {
            table = $('#studentTable').DataTable({
              scrollY: '19rem',
              fixedHeader: true,
              autoWidth: false,
              info: false,
              searching: false,
              paging: false,
              data: response.data.studentList,
              columns: [
                { title: 'Name', data: 'name' },
                { title: 'Username', data: 'username' },
                { title: 'Email Address', data: 'email' },
                { data: 'userID', visible: false },
                null,
              ],
              columnDefs: [{
                targets: -1,
                orderable: false,
                defaultContent: "<button type='button' class='btn bg-transparent text-danger'><i class='material-icons'>delete</i></button>",
              }],
            });
            const self = this;
            // eslint-disable-next-line func-names, space-before-function-paren
            $('#studentTable tbody').on('click', 'button', function() {
              const data = table.row($(this).parents('tr')).data();
              const idToRemove = data.userID;
              self.classIDs.students = self.classIDs.students.filter((e) => e.userID
                !== idToRemove);
              table.row($(this).parents('tr')).remove().draw();
            });
          }, 0);
        })
        .catch((err) => {
          this.$store.commit('setLoadScreen', { status: false });
          console.log(err);
        });
    },
    searchStudents(event) {
      const { schoolId } = this.userData;
      if (event.key === 'Enter') {
        if (this.studentResults.length === 0) {
          this.inviteStudent();
          return;
        }
        this.classIDs.students.push(this.studentResults[this.focusItem].userID);
        table.row.add(this.studentResults[this.focusItem]).draw();
        this.studentQuery = '';
        this.studentQueryResults = [];
      } else if (event.key === 'ArrowDown') {
        if (this.studentResults.length > 0) {
          this.focusItem = Math.min(this.focusItem + 1, this.studentResults.length - 1);
        }
      } else if (event.key === 'ArrowUp') {
        this.focusItem = Math.max(this.focusItem - 1, 0);
      } else {
        if (this.studentQuery.length < 1) { this.studentQueryResults = []; return; }
        axios.get(`/api/account/search/student?schoolID=${schoolId}&phrase=${this.studentQuery}`)
          .then((response) => {
            this.studentQueryResults = response.data;
          });
        this.focusItem = 0;
      }
    },
    addStudent(idx) {
      table.row.add(this.studentResults[idx]).draw();
      this.classIDs.students.push(this.studentResults[idx]);
      this.studentQuery = '';
      this.studentQueryResults = [];
    },
    inviteStudent() {
      const { schoolId } = this.userData;
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(String(this.studentQuery).toLowerCase())) {
        Swal.fire({
          title: `Invite ${this.studentQuery} to join your course?`,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, invite student',
        }).then((result) => {
          if (result.isConfirmed) {
            axios.post('/api/account/invite_to_course', {
              email: this.studentQuery,
              schoolid: schoolId,
            }).then((response) => {
              table.row.add(response.data).draw();
              this.classIDs.students.push(response.data);
              this.studentQuery = '';
              this.studentQueryResults = [];
            });
          } else if (result.isDismissed) {
            this.studentQuery = '';
            this.studentQueryResults = [];
          }
        });
      } else {
        Swal.fire({
          title: 'Invalid email',
          icon: 'error',
          text: 'Please enter a valid email address',
        });
      }
    },
    deleteAllStudents() {
      Swal.fire({
        title: 'Are you sure you want to delete all students?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete all students',
      }).then((result) => {
        if (result.isConfirmed) {
          table.clear().draw();
          this.classIDs.students = [];
        }
      });
    },
    submitNewRoster() {
      this.$store.commit('setLoadScreen', { status: true });
      const memberData = {
        students: this.classIDs.students,
        courseId: this.courseId,
        role: this.$store.state.userData.roleId,
      };
      axios.post('/api/course/updateMembers', memberData)
        .then(() => this.getMembers())
        .then(() => {
          this.$store.commit('setLoadScreen', { status: false });
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit('setLoadScreen', { status: false });
        });
    },
    discardChanges() {
      this.$router.replace({ path: `/courses/${this.courseId}/info` });
    },
  },
  mounted() {
    this.getMembers();
  },
};
</script>

<style scoped>
.autocomplete-results {
  background-color: #fff;
  box-shadow: .125rem .25rem rgba(236,171,50,0.3);
  text-align: left;
  width: 100%;
  border-radius: 1rem;
  z-index: 100;
}

.autocomplete-item {
  padding: 0.5rem 0.5rem;
  margin-bottom: 0px;
}

.autocomplete-item:hover {
  cursor: pointer;
  background-color: #FCF0D9;
  color: #000;
}

.subject-query-field {
  border-radius: 1rem;
}

.subject-query-field.query-results {
  border-radius: 1rem 1rem 0px 0px;
}

.autocomplete-item.focus {
  background-color: #ECAB32;
  color: #000;
}
</style>
