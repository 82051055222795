<template>
  <div v-if="!loadScreen">
    <div class="card left-accent-card course-create-content" >
      <div class="card-body">
        <form @submit.prevent class="container">
          <div class="form-group">
            <label for="courseTitle" class="form-label">Course Title</label>
            <input type="text" class="form-control" id="courseTitle"
            v-model="newClass.title" required>
          </div>
          <div class="form-group mt-4">
            <label for="courseCode" class="form-label">Course Code</label>
            <input type="text" class="form-control" id="courseCode"
            v-model="newClass.code" required>
          </div>
          <hr/>
          <div class="form-group row" v-if="editMainInst">
            <label for="mainInstructor" class="col-md-3 col-form-label">Main instructor</label>
            <div class="col-md-8 pe-4">
              <input type="text" class="form-control student-query-field"
              placeholder="Search for staff..." v-model="staffQuery"
              @keyup="searchStaff"
              :class="{ 'query-results' : staffResults.length > 0 }">
              <div class="position-relative p-0">
                <div class="autocomplete-results position-absolute"
                v-if="staffResults.length > 0">
                  <ul class="autocomplete-item" v-for="staff, idx in staffResults"
                  :key="idx" :class="{ focus : idx === focusItem }"
                  @click="addMainInst(idx)">
                    {{ staff.name }}<br>
                    {{ staff.username }}<br>
                    {{ staff.email}}
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-1 text-end">
              <button type="button" class="btn btn-link" @click="editMainInst = false"
              style="padding: 0.375rem 0rem;">Cancel</button>
            </div>
          </div>
          <div class="form-group row" v-else>
            <label for="mainInstructor" class="col-md-3 col-form-label">Main instructor</label>
            <div class="col-md-8 pe-4">
              <input type="text" readonly
              class="form-control d-inline"
              id="mainInstructor"
              v-model="mainInstructor">
            </div>
            <div class="col-md-1 text-end">
              <button type="button" class="btn btn-link" @click="editMainInst = true"
              style="padding: 0.375rem 0rem;">Change</button>
            </div>
          </div>
          <hr/>
          <div class="form-group row">
            <label class="col-md-3 col-form-label">
              Additional staff
              <i class="material-icons" data-toggle="tooltip"
              title="Additional staff can create and grade assignments"
              style="font-size: 20px;">help_outline</i>
            </label>
            <div class="form-group col-md-9 ml-sm-auto">
              <div v-for="i, index in classIDs.staff" class="input-group mb-3" :key="index">
                <input class="form-control d-inline me-2" readonly type="text"
                :value="i.name">
                <select class="form-select ms-2 me-5" v-model="i.role">
                  <option v-for="role, ind in courseRoles" :value="role.id" :key="ind">
                    {{ role.role }}
                  </option>
                </select>
                <div class="input-group-append">
                  <button type="button" class="btn btn-link" @click="removeStaff(index)"
                  style="padding: 0px;">Remove</button>
                </div>
              </div>
              <div class="form-group" v-if="addingStaffDetails">
                <div class="input-group row mx-0 g-0">
                  <div class="col-md-11 ps-0 pe-5">
                    <input type="text" class="form-control student-query-field"
                    placeholder="Search for staff..." v-model="staffQuery"
                    @keyup="searchStaff"
                    :class="{ 'query-results' : staffResults.length > 0 }">
                    <div class="position-relative p-0">
                      <div class="autocomplete-results position-absolute"
                      v-if="staffResults.length > 0">
                        <ul class="autocomplete-item" v-for="staff, idx in staffResults"
                        :key="idx" :class="{ focus : idx === focusItem }" @click="addStaff(idx)">
                          {{ staff.name }}<br>
                          {{ staff.username }}<br>
                          {{ staff.email}}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-1 text-end">
                    <button type="button" class="btn btn-link"
                    @click="addingStaffDetails = false"
                    style="padding: 0px;">Cancel</button>
                  </div>
                </div>
                <small class="text-danger" v-show="addStaffDetails.error == 2">
                  Staff member already added
                </small>
              </div>
              <button type="button" class="btn btn-link" v-else @click="openStaffDetails"
              style="padding: 0.375rem 0rem;">Add additional staff</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="mt-5 text-end">
      <div v-if="editing">
        <button type="button" class="float-end btn btn-primary"
        @click="submitNewClass" :disabled="!changesMade">
          Save changes
        </button>
        <button type="button" class="float-end btn btn-secondary me-2"
        @click="discardChanges">Cancel</button>
      </div>
      <div v-else>
        <button type="button" class="btn btn-primary ms-2"
        @click="submitNewClass">Create Class</button>
        <button type="button" class="btn btn-secondary ms-2"
        @click="$parent.cancelNewClass">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
// import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import Swal from 'sweetalert2';

// let table = null;

export default {
  name: 'CourseDetails',
  props: ['editing', 'courseId'],
  computed: {
    loadScreen() {
      return this.$store.state.loadScreen;
    },
    userData() {
      return this.$store.state.userData;
    },
    staffResults() {
      const tempList = [];
      this.staffQueryResults.forEach((staff) => {
        const exists = this.classIDs.staff.some((e) => (e.userID === staff.userID))
          || this.newClass.instructor === staff.userID;
        if (!exists) {
          tempList.push(staff);
        }
      });
      return tempList;
    },
    changesMade() {
      const classData = JSON.stringify({
        title: this.newClass.title,
        instructor: this.newClass.instructor,
        staff: this.classIDs.staff,
        code: this.newClass.code,
        role: this.$store.state.userData.roleId,
      });
      return this.oldClassData !== classData;
    },
  },
  data() {
    return {
      userInfo: null,
      oldClassData: null,
      newClass: {
        title: '',
        code: '',
        instructor: '',
        staff: [],
      },
      classIDs: {
        staff: [],
        students: [],
      },
      addStudentDetails: {
        name: '',
        username: '',
        email: '',
        userID: null,
        fieldID: 'addStudent',
        error: 0,
      },
      addStaffDetails: {
        name: '',
        username: '',
        email: '',
        userID: null,
        fieldID: 'addStaff',
        error: 0,
      },
      addingStaffDetails: false,
      mainInstructor: null,
      editMainInst: false,
      staffQuery: '',
      staffQueryResults: [],
      focusItem: null,
      courseRoles: [],
    };
  },
  methods: {
    getDetails() {
      this.$store.commit('setLoadScreen', { status: true });
      if (this.courseId) {
        this.$parent.editing = true;
        axios.get('/api/course/getDetails', {
          params: {
            cId: this.courseId,
            // cId: this.$route.query.cId,
            role: this.userData.roleId,
          },
        })
          .then((response) => {
            this.newClass.title = response.data.courseDetails.title;
            this.newClass.instructor = response.data.courseDetails.instructor;
            this.newClass.code = response.data.courseDetails.code;
            this.mainInstructor = response.data.courseDetails.instructorName;
            this.newClass.staff = response.data.staffList;
            this.classIDs.staff = response.data.staffList;
            this.oldClassData = JSON.stringify({
              title: this.newClass.title,
              instructor: this.newClass.instructor,
              staff: this.classIDs.staff,
              code: this.newClass.code,
              role: this.$store.state.userData.roleId,
            });
            // response.data.staff.forEach((e) => this.classIDs.staff.push(e.userID));
            // response.data.students.forEach((e) => this.classIDs.students.push(e.userID));
            this.$store.commit('setLoadScreen', { status: false });
          })
          .catch((err) => {
            this.$store.commit('setLoadScreen', { status: false });
            console.log(err);
          });
      } else {
        // axios.get('/api/account/info', {
        //   params: {
        //     id: this.$store.state.userData.id,
        //   },
        // })
        //   .then((response) => {
        // this.userInfo = response.data;
        this.newClass.instructor = this.userData.id;
        this.mainInstructor = `${this.userData.givenName} ${this.userData.lastName}`;
        this.$store.commit('setLoadScreen', { status: false });
        // });
      }
    },
    openStaffDetails() {
      this.addingStaffDetails = true;
    },
    removeStaff(index) {
      const temp = this.classIDs.staff;
      const idToRemove = this.classIDs.staff[index].userID;
      temp.splice(index, 1);
      this.newClass.staff = temp;
      this.classIDs.staff = this.classIDs.staff.filter((e) => e !== idToRemove);
    },
    cancelNewClass() {
      this.$router.replace({ path: '/courses/' });
    },
    searchStaff(event) {
      const { schoolId } = this.userData;
      if (event.key === 'Enter') {
        this.classIDs.staff.push(this.staffQueryResults[this.focusItem]);
        this.studentQuery = '';
        this.staffQueryResults = [];
      } else if (event.key === 'ArrowDown') {
        this.focusItem = Math.min(this.focusItem + 1, this.staffQueryResults.length - 1);
      } else if (event.key === 'ArrowUp') {
        this.focusItem = Math.max(this.focusItem - 1, 0);
      } else {
        if (this.staffQuery.length < 1) { this.staffQueryResults = []; return; }
        axios.get(`/api/account/search/staff?schoolID=${schoolId}&phrase=${this.staffQuery}`)
          .then((response) => {
            this.staffQueryResults = response.data;
          });
        this.focusItem = 0;
      }
    },
    addMainInst(idx) {
      this.mainInstructor = this.staffResults[idx].name;
      this.newClass.instructor = this.staffResults[idx].userID;
      this.editMainInst = false;
      this.staffQuery = '';
      this.staffQueryResults = [];
    },
    addStaff(idx) {
      this.staffResults[idx].role = 2;
      this.classIDs.staff.push(this.staffResults[idx]);
      // table.row.add(this.staffResults[idx]).draw();
      this.staffQuery = '';
      this.staffQueryResults = [];
      this.addingStaffDetails = false;
    },
    submitNewClass() {
      if (this.newClass.title === '') {
        Swal.fire({
          title: 'Please give your course a title',
          icon: 'error',
        });
        return;
      }
      if (this.newClass.code === '') {
        Swal.fire({
          title: 'Please give your course a code',
          icon: 'error',
        });
        return;
      }
      // if (this.classIDs.students.length === 0) {
      //   Swal.fire({
      //     title: 'Cannot create course with no students',
      //     text: 'Please add at least one student',
      //     icon: 'error',
      //   });
      //   return;
      // }
      const classData = {
        title: this.newClass.title,
        instructor: this.newClass.instructor,
        staff: this.classIDs.staff,
        code: this.newClass.code,
        // students: this.classIDs.students,
        role: this.$store.state.userData.roleId,
      };
      this.$store.commit('setLoadScreen', { status: true });
      if (this.editing) {
        classData.courseId = this.courseId;
        axios.post('/api/course/editDetails', classData)
          .then(() => {
            this.$store.commit('setLoadScreen', { status: false });
            this.oldClassData = JSON.stringify({
              title: this.newClass.title,
              instructor: this.newClass.instructor,
              staff: this.classIDs.staff,
              code: this.newClass.code,
              role: this.$store.state.userData.roleId,
            });
          }).catch((err) => {
            this.$store.commit('setLoadScreen', { status: false });
            console.log(err);
          });
      } else {
        axios.post('/api/course/create', classData)
          .then((response) => {
            this.$router.push(`?cId=${response.data.courseId}`);
            this.$parent.nextTab();
            this.$store.commit('setLoadScreen', { status: false });
            this.$parent.editing = true;
          }).catch((err) => {
            this.$store.commit('setLoadScreen', { status: false });
            console.log(err);
          });
      }
    },
    discardChanges() {
      this.$router.replace({ path: `/courses/${this.courseId}/info` });
    },
    getCourseRoles() {
      axios.get('/api/course/roles')
        .then((response) => {
          this.courseRoles = response.data.courseRoles;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.getCourseRoles();
    this.getDetails();
  },
};
</script>

<style scoped>
.autocomplete-results {
  background-color: #fff;
  box-shadow: .125rem .25rem rgba(236,171,50,0.3);
  text-align: left;
  width: 100%;
  border-radius: 1rem;
  z-index: 100;
}

.autocomplete-item {
  padding: 0.5rem 0.5rem;
  margin-bottom: 0px;
}

.autocomplete-item:hover {
  cursor: pointer;
  background-color: #FCF0D9;
  color: #000;
}

.subject-query-field {
  border-radius: 1rem;
}

.subject-query-field.query-results {
  border-radius: 1rem 1rem 0px 0px;
}

.autocomplete-item.focus {
  background-color: #ECAB32;
  color: #000;
}
</style>
